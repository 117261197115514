import React from "react";
import { graphql } from "gatsby";
import SportPage from "./sport-page";

export default (props) => <SportPage {...props} />;

export const SportsPageQuery = graphql`
  query SportsPageNLByID($id: String!) {
    content: markdownRemark(id: { eq: $id }) {
      id
      fields {
        langKey
      }
      frontmatter {
        title
        online
        usp1
        usp2
        usp3
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        clients {
          client {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        quote_image {
          childImageSharp {
            fluid(maxWidth: 150, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sport_image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quote
        quote_author
        unique_features {
          feature1
          feature1_description
          feature1_icon
          feature2
          feature2_description
          feature2_icon
          feature3
          feature3_description
          feature3_icon
          feature4
          feature4_description
          feature4_icon
        }
      }
    }
    pricing: markdownRemark(
      frontmatter: { templateKey: { eq: "pricing" }, languageKey: { eq: "nl" } }
    ) {
      frontmatter {
        pricing {
          heading
          description
          tournament
          online
          qta
          url
          pertournament
          forever
          free
          online_tooltip
          online_description
          amateur {
            name
            feature1
            feature2
            feature3
          }
          worldclass {
            name
            feature1
            feature2
            feature3
          }
          legendary {
            name
            feature1
            feature2
            feature3
          }
          onlinefree {
            name
            feature1
            feature2
            feature3
          }
          pro {
            name
            feature1
            feature2
            feature3
          }
          subscription {
            name
            description1
            description2
            request_brochure
            email
            request
            request_thanks
            request_failed
          }
        }
      }
    }
  }
`;
